import { css } from '@emotion/core'
import { mq } from './Layout.styles'

export const styledWhyHostdimeBps = css(
  mq({
    marginTop: [49.5, 50, 47, 47],
    marginBottom: [50]
  })
)

export const styledWhyHostdime = css`
  ${styledWhyHostdimeBps};
`

export const H4ContentBps = css(
  mq({
    maxWidth: [328, '100%', 680, 680],
    marginLeft: [0, 0, 200, 200],
    marginRight: [0, 0, 200, 200],
    marginBottom: [16],
    letterSpacing: [0.25],
    textAlign: ['left'],
    fontSize: [33.6, 33.6, 33.6, 47.9],
    fontWeight: [600, 600, 600, 'normal']
  })
)

export const PContentBps = css(
  mq({
    maxWidth: [328, '100%', 813, 813],
    marginLeft: [0, 0, 200, 200],
    marginRight: [0, 0, 200, 200],
    textAlign: ['left']
  })
)

export const H6ContentBps = css(
  mq({
    marginLeft: [16, 0, 0, 0],
    textAlign: ['left']
  })
)

export const lineBorderBps = css(
  mq({
    maxWidth: [328, 422, 680, 680]
  })
)

export const sizeBox = css(
  mq({
    maxWidth: ['100%', '100%', 490, 308],
    marginLeft: ['auto']
  })
)

export const content = css`
  display: flex;
  text-align: center;
  h4 {
    ${H4ContentBps};
    color: rgba(0, 0, 0, 0.87);
  }
  h6 {
    ${H6ContentBps};
    letter-spacing: 0.25px;
    color: rgba(0, 0, 0, 0.6);
  }
  p {
    ${PContentBps};
    margin-bottom: 16px;
  }
  h6:nth-last-child(2n) {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  img {
    width: 16.6px;
  }
`
export const styledBox = css`
  img {
    max-width: 130px;
  }
  h5 {
    color: rgba(0, 0, 0, 0.6);
    margin-top: 16px;
    margin-bottom: 8px;
  }
  p {
    color: rgba(0, 0, 0, 0.6);
  }
`