import styled from '@emotion/styled';
import { css } from '@emotion/core'

const btnSizes = {
  large: 'max-width: 328px',
  small: 'max-width: 200px',
};

export const btn = css`
  width: 100%;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  border: none;
  border-radius: 22.5px;
  padding: 15px 16px 14px;
  vertical-align: middle;
  text-align: center;
  text-overflow: ellipsis;
  text-transform: uppercase;
  color: #fff;
  background-color: #ff5800;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  font-family: 'Montserrat', sans-serif;
  font-size: 0.85625rem;
  font-weight: 600;
  line-height: 1.16;
  letter-spacing: 1.25px;
  overflow: hidden;
  outline: none;
  cursor: pointer;
  transition: box-shadow 0.2s;
  ::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgb(var(--pure-material-onprimary-rgb, 255, 255, 255));
    opacity: 0;
    transition: opacity 0.2s;
  }
  ::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 50%;
    padding: 50%;
    width: 32px; /* Safari */
    height: 32px; /* Safari */
    background-color: rgb(var(--pure-material-onprimary-rgb, 255, 255, 255));
    opacity: 0;
    transform: translate(-50%, -50%) scale(1);
    transition: opacity 1s, transform 0.5s;
  }
  :hover {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    ::before {
      opacity: 0.08;
    }
    :focus {
      ::before {
        opacity: 0.3;
      }
    }
  }
  :focus {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    ::before {
      opacity: 0.24;
    }
  }
  :active {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
    ::after {
      opacity: 0.32;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0s;
    }
  }
  :disabled {
    color: #fff;
    background-color: #ff5800;
    box-shadow: none;
    cursor: initial;
    ::before {
      opacity: 0;
    }
    ::after {
      opacity: 0;
    }
  }
`

export const btnLarge = css`
  ${btn};
  max-width: 328px;
`
export const btnContainedContrato = css`
  ${btn};
  max-width: 260px;
  color: #fff !important;
`
export const btnSmall = css`
  ${btn};
  max-width: 200px;
`
export const btnBuyLicence = css`
  ${btn};
  max-width: 140px;
  box-shadow: none;
`
export const btnContainedLink = css`
  ${btn};
`
export const btnContainedNav = css`
  ${btn};
  justify-content: center;
`
export const btnLargeWhite = css`
  ${btn};
  max-width: 290px;
  background-color: rgb(255, 255, 255);
  color: #666666;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 1.43;
`
export const btnLargeWhiteSmall = css`
  ${btn};
  max-width: 200px;
  background-color: rgb(255, 255, 255);
  color: #666666;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 1.16;
`
export const btnExpert = css`
  ${btn};
  max-width: 220px;
  background-color: rgb(255, 255, 255);
  font-size: 13.7px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.16;
  letter-spacing: 1.25px;
  text-align: center;
  color: #4e5159;
`

export const btnIso = css`
  ${btn};
  max-width: 270px;
  background-color: #f9fafa;
  font-size: 13.7px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.16;
  letter-spacing: 1.25px;
  text-align: center;
  color: #4e5159;
`

export const btnLargeWhiteLitleSmall = css`
  ${btn};
  max-width: 150px;
  background-color: rgb(255, 255, 255);
  color: #666666;
  font-weight: 600;
  letter-spacing: normal;
`
export const btnContainedForm = css`
  ${btn};
  max-width: 210px;
`
export const btnFooter = css`
  ${btn};
  max-width: 104px;
  background-color: #f9fafa;
  font-size: 13.7px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.16;
  letter-spacing: 1.25px;
  text-align: center;
  color: #4e5159;
`
export const Button = styled.button`
  ${btn};
  ${({ size }) => btnSizes[size] || 'max-width: 100%'};
  background-color: ${({ disabled }) => disabled && '#E0E0E0'};
  color: ${({ disabled }) => disabled && '#B6C4CB'};
`;