import React from 'react'
import { Link } from 'gatsby'
import {
  btn,
  btnLarge,
  btnContainedLink,
  btnSmall,
  btnLargeWhite,
  btnLargeWhiteSmall,
  btnLargeWhiteLitleSmall,
  btnContainedForm,
  btnContainedContrato,
  btnExpert,
  btnFooter,
  btnBuyLicence,
  btnIso
} from './styles/ContainedButton.styles'

const Button = (props) => {
  const buttonStyle = 
  props.type === 'btnContainedLarge' ? btnLarge : 
  props.type === 'btnContainedSmall' ? btnSmall : 
  props.type === 'btnOutlinedLink'  ? btnContainedLink :
  props.type === 'btnOutlinedWhiteLarge'  ? btnLargeWhite :
  props.type === 'btnContainedWhiteSmall'  ? btnLargeWhiteSmall :
  props.type === 'btnContainedWhiteLitleSmall'  ? btnLargeWhiteLitleSmall :
  props.type === 'btnContainedForm'  ? btnContainedForm :
  props.type === 'btnContainedContrato'  ? btnContainedContrato :
  props.type === 'btnExpert'  ? btnExpert :
  props.type === 'btnBuyLicence'  ? btnBuyLicence :
  props.type === 'btnFooter'  ? btnFooter :
  props.type === 'btnIso'  ? btnIso :
  btn
  return (
    <Link
      to={props.Link}
      css={buttonStyle}
    >
      { props.children }
    </Link>
  )
}


export default Button
 