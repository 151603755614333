import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { graphql }  from 'gatsby'
import Img from "gatsby-image"

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Div from '../components/Div'
import ContainedButton from '../components/ContainedButton'

import H4 from '../components/H4'
import H5 from '../components/H5'
import H6 from '../components/H6'
import Paragraph from '../components/Paragraph'
import ImgNossoAtendimento from '../images/nosso-atendimento.svg'
import ImgNossosCertificados from '../images/nossos-certificados.svg'
import Check from '../images/tick.svg'

import { Container, mq } from '../components/styles/Layout.styles'
import { 
  styledWhyHostdime,
  content,
  styledBox,
  sizeBox
} from '../components/styles/WhyHostdime.styles'
import css from '@emotion/css'

export const PageTemplate = ({
  data
}) => {
  console.log(data)
  return (
    <Fragment>
      <section css={styledWhyHostdime}>
        <Container>
          <Row
            flexDirection={['column']}
            css={content}
          >
            <Column
              width={[1]}
            >
              <H4>Por que a HostDime?</H4>
              <Paragraph>A HostDime oferece infraestrutura certificada com tecnologia de ponta para a operação dos sistemas críticos que são fundamentais para o funcionamento de uma organização.</Paragraph>
              <Paragraph>Garantimos soluções eficientes, atendimento personalizado e uma equipe de profissionais experientes para que nossos clientes tenham todo o apoio necessário para se tornarem mais competitivos no mercado. </Paragraph>
            </Column>
          </Row>
        </Container>
      </section>
      <section>
        <Container
          css={css(
            mq({
              padding: [0, 0, 'initial'],
              margin: [0, 0, 'initial'],
              maxWidth: ['100%', '100%', 'initial']
            })
          )}
        >
          <Row
            flexDirection={['column', 'column', 'row']}
            mx={null}
          >
            <Column
              pt={[50]}
              pb={[50]}
              pl={[35, 90, 68, 110]}
              pr={[35, 90, 68, 110]}
              px={null}
              width={[1, 1, 1/2, 1/2]}
              style={{
                backgroundColor: '#f9fafa'
              }}
              css={styledBox}
            >
              <div
                css={sizeBox}
              >
                <img
                  src={ImgNossoAtendimento} 
                />
                <H5>Nosso Atendimento</H5>
                <Paragraph
                  width={[267, 422, 414, 414]}
                  style={{
                    marginBottom: 30
                  }}
                >
                  Atendimento multiplataforma ágil, eficiente e personalizado. Disponível sempre que você precisar.
                </Paragraph><br /><br />
                <ContainedButton
                  type='btnContainedWhiteLitleSmall'
                  Link='/nosso-atendimento'
                >
                  SAIBA MAIS
                </ContainedButton>
              </div>
            </Column>
            <Column
              pt={[50]}
              pb={[50]}
              pl={[35, 90, 68, 110]}
              pr={[35, 90, 68, 110]}
              px={null}
              width={[1, 1, 1/2, 1/2]}
              style={{
                backgroundColor: '#f3f5f5'
              }}
              css={styledBox}
            >
              <img
                src={ImgNossosCertificados} 
              />
              <H5>Premiações e Certificados</H5>
              <Paragraph
                width={[267, 422, 422, 422]}
                style={{
                  marginBottom: 30
                }}
              >
                Conheça nossas premiações e os nossos certificados de abrangência internacional que asseguram a nossa alta capacidade de atendimento e confiabilidade de infraestrutura local.
              </Paragraph>
              <ContainedButton
                type='btnContainedWhiteLitleSmall'
                Link='/premiacoes-e-certificados'
              >
                VEJA AQUI
              </ContainedButton>
            </Column>
          </Row>
        </Container>
      </section>
      <section id='quero-testar'>
        <Div
          px={[16, 16, 24, 24]}
          alignItems={['center']}
        >
          <Div
            maxWidth={['100%', 390, 855, 1086]}
          >
            <Row
              flexDirection={['column', 'column', 'row', 'row']}
              mt={[34.5, 35, 70, 70]}
              mx={[-16, -16, 0, 0]}
              alignItems={['center', 'end']}
            >
              <Column
                width={[1, 1, 1, 1/2]}
               
                style={{
                  justifyContent: 'center'
                }}
              >
                <Div
                  maxWidth={['100%', '100%', 400, 450]}
                >
                  <Img
                    fluid={data.image1.childImageSharp.fluid}
                    loading='eager'
                  />
                </Div>
              </Column>
              <Column
                width={[1, 1, 1, 1/2]}
                mb={[100]}
              >
                <Div
                  maxWidth={['100%', '100%', 360, 360]}
                  css={content}
                >
                  <H6><img src={Check} /> Empresa global com presença em mais de 8 países</H6>
                  <H6><img src={Check} /> Baixa latência</H6>
                  <H6><img src={Check} /> Atendimento diferenciado com gerentes de contas</H6>
                  <H6><img src={Check} /> Mais de 15 anos de experiência.</H6>
                  <H6><img src={Check} /> Soluções de tecnologia de ponta em configurações ágeis, otimizadas e flexíveis para um melhor desempenho.</H6>
                  <H6><img src={Check} /> Flexibilidade de pagamento em um serviço cobrado em reais, sem influência da flutuação cambial ou impostos de importação.</H6>
                  {/* <Div
                    display={['block', 'block', 'none']}
                    mb={[5]}
                    maxWidth={[310, 310, 327, 360]}
                  >
                    <Img
                      fluid={data.image1.childImageSharp.fluid}
                      loading='eager'
                    />
                  </Div> */}
                </Div>
              </Column>
            </Row>
          </Div>
        </Div>
      </section>
    </Fragment>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
  return (
    <Layout>
      <Seo 
        title='Escolha o melhor data center | HostDime Brasil'
        description='Infraestrutura certificada, tecnologia de ponta, baixa latência, pagamento em reais e mais. Descubra por que somos o Data Center para você.'
      />
      <PageTemplate
        title='Por que a Hostdime?'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "sobre/por_que_a_hostdime.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default StaticPage
